import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Banner from '../components/banner';
import SubHero from '../components/subHero';
import Modules from '../components/modules';

const NotFoundPage = ({ data }) => {
  const seoTitle = data.strapiPage.seo
    ? data.strapiPage.seo.seoTitle
    : data.strapiPage.title;
  const seoDesc = data.strapiPage.seo ? data.strapiPage.seo.seoDescription : '';

  return (
    <Layout classNames={`page-template-default page ${data.strapiPage.id}`}>
      <Seo
        title={data.strapiPage.title}
        seoTitle={seoTitle}
        seoDescription={seoDesc}
        featImage={
          data?.strapiPage?.pageBanner?.bannerImage?.formats?.large?.url
        }
      />
      {data.strapiPage.pageBanner.bannerSize !== 'useUpdatedHero' && (
        <Banner data={data.strapiPage.pageBanner} />
      )}
      {data.strapiPage.subHero && data.strapiPage.subHero.active && (
        <SubHero data={data.strapiPage.subHero} />
      )}
      <section className="content-wrap">
        {data.strapiPage.modules && (
          <Modules modules={data.strapiPage.modules} />
        )}
      </section>
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query NotFoundPage {
    strapiPage(slug: { eq: "keeping-it-fresh" }) {
      id
      seo {
        excludeFromSitemap
        noFollow
        noIndex
        seoTitle
        seoDescription
      }
      title
      featuredImage {
        alternativeText
        url
      }
      pageBanner {
        bannerCaption
        bannerCtaButtons {
          link
          text
        }
        bannerHaveMarquee
        bannerImage {
          alternativeText
          url
          formats {
            large {
              url
            }
          }
        }
        bannerImageOrVideo
        bannerIncorporateVideo
        bannerOverlay
        textColor
        bannerSize
        bannerTitle
        fullWidthTextBlock
        textBlockContentAlignment
        textBlockHorizontalAlignment
        textBlockVerticalAlignment
        logoMarquee {
          logo {
            alternativeText
            url
          }
          title
          text
        }
        video {
          videoSource
          videoId
          videoThumbnail {
            alternativeText
            url
          }
        }
        fullScreenVideoThumbnail {
          alternativeText
          url
        }
        mp4Video {
          url
        }
        webmVideo {
          url
        }
        oggVideo {
          url
        }
      }
      subHero {
        active
        content
        numberOfColumns
        problemText
        problemTitle
        solutionText
        solutionTitle
        title
      }
      modules
    }
  }
`;

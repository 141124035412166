import React from "react"
import Link from "./link"
import { htmlDecode } from "../Utils"

const SubHero = ({
  data = null
}) => {
  return (
    <div className="sub-hero-block mb6">
      <div className="container">

        {data.numberOfColumns === 1 &&
          <div className="row jcc">
            <div className="col-12">
              <div className="subhero sub134 one-col" style={{marginTop: '-10rem'}}>
                <h2 className="module-header">{ htmlDecode(data.title) }</h2>
                <div className="section-content" dangerouslySetInnerHTML={{ __html: data.content }} />
              </div>
            </div>
          </div>
        }

        {data.numberOfColumns === 2 &&
          <div className="row jcc">
            <div className="col-12">
              <div className="subhero sub2 two-col" style={{marginTop: '-9rem'}}>
                <div className="row">
                  <div className="col-md-12 col-lg-6 the-problem bgg1">
                    <div className="col-9 offset-1 offset-sm-1 offset-lg-2 ph3 pv3">
                      <h2 className="module-header">{ htmlDecode(data.problemTitle) }</h2>
                      <div className="copy" dangerouslySetInnerHTML={{ __html: data.problemText }} />
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-6 bg8 the-solution">
                    <div className="col-9 offset-1 ph3 pv3">
                      <h2 className="module-header tcw">{ htmlDecode(data.solutionTitle) }</h2>
                      <div className="copy tcw" dangerouslySetInnerHTML={{ __html: data.solutionText }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

        {data.numberOfColumns >= 3 &&
          <p>FINISH</p>
        }

      </div>
    </div>
  )
}

export default SubHero
